import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "@herb/layouts";
import ShareLinks from "@herb/components/ShareLinks";
import CategoryAndDate from "@herb/components/CategoryAndDate";
import WrittenBy from "@herb/components/WrittenBy";
// import StickySideArticle from "@herb/components/Article/StickySideArticle";
// import StickySideNewsletter from "@herb/components/StickySideNewsletter";
import ImageForArticlePage from "@herb/components/ImageForArticlePage";
import DescriptionForArticlePage from "@herb/components/DescriptionForArticlePage";
import BoldTextForArticlePage from "@herb/components/BoldTextForArticlePage";
import SectionHeading from "@herb/components/SectionHeading";
import Image from "@herb/components/Image";
import Button from "@herb/components/Button";
import PostVideo from "@herb/components/Post/PostVideo";
import ProductCard from "@herb/components/Cards/ProductCard";
import PostIframe from "@herb/components/Post/PostIframe";
import PostInstagram from "@herb/components/Post/PostInstagram";
import PostTweet from "@herb/components/Post/PostTweet";
import JsonLd from "@herb/components/Schema/JsonLd";
import SEO from "@herb/components/seo";
import DisplayAd from "@herb/components/DisplayAd";
import { paths, deployUrl, categoryPath, displayAdsToHide } from "@herb/utils";
import Container from "@herb/components/Container";
import ReactHtmlParser from "react-html-parser";
import { Link } from "@reach/router";
import getCategoryPath from "@herb/utils/categoryPath";
import CategoryTags from "@herb/components/CategoryTags";
import { generateRandom } from "@herb/utils/randomNumber";
import ArticleProduct from "@herb/components/ProductForArticlePage";
import LatestArticleLoadAlgolia from "@herb/components/Article/LatestArticleLoadAlgolia";
import LoadRandomProducts from "../../components/Article/LoadRandomProducts";
import LoadRandomBrands from "../../components/Article/LoadRandomBrands";
import Modal from "react-modal";
import { Icon, Box } from "@herb/ui";
import TrichomePopUpForm from "../../components/ILGMPopup";
import { Helmet } from "react-helmet";
import RelatedLinksCard from "@herb/components/Cards/RelatedLinksCard";
import SidebarBrandsAndProducts from "../../components/SidebarBrandsAndProducts";
import {
  scriptCreate as aggleScript,
  includePAXScript
} from "@herb/utils/scripts";
import "./index.css";
import ArticleBreadCrumb from "../../components/ArticleBreadCrumb";

import ScavengerHuntPopUp from "../../components/ScavengerHuntPopUp/index";
const isBrowser = typeof window !== "undefined";

const LOGO_URL = `${deployUrl}/icons/icon-512x512.png`;

const PostTemplate = ({ data, pageContext }) => {
  const {
    publishDate,
    related_links: pagelinks,
    related_links_page,
    noindex,
    catPath,
    catName,
    catSlug
  } = pageContext;

  const ilgmSlugs = [
    "/learn/get-20-white-widow-seeds-for-the-price-of-10-at-ilgm/",
    "/news/culture/get-seeds-you-can-trust-with-expert-growing-advice-from-ilgm/",
    "/learn/get-20-white-widow-seeds-for-the-price-of-10-at-ilgm",
    "/news/culture/get-seeds-you-can-trust-with-expert-growing-advice-from-ilgm"
  ];

  const paxSlugs = [
    "/learn/how-to-boost-your-vaping-experience",
    "/learn/is-dry-herb-vaping-safe-dry-herb-vaporizer-health-risks",
    "/learn/comparing-pax-devices"
  ];

  const {
    wordpressWpPost: currentPost,
    newPosts: { nodes: latestArticles },
    sameCatPosts: { nodes: sameCatArticle = [] }
  } = data;
  // const stickyArticles = latestArticles.splice(0, 2);

  const hideRecommendedProductsSection =
    currentPost.hideRecommendedProducts?.hide;

  const [trichomeOpen, setTrichomeOpen] = useState(false);
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [showSticker, setShowSticker] = useState(false);

  const handleEmailPopup = () => {
    setEmailPopupOpen(v => !v);
  };

  const convertToPST = dateString => {
    const date = new Date(dateString);
    const options = {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);

    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year }
    ] = formatter.formatToParts(date);
    return `${year}-${month}-${day}`;
  };

  const delay = () => {
    setTimeout(() => {
      setTrichomeOpen(true);
    }, 8000);
  };

  // Getting the date and converting into PST and comparing
  useEffect(() => {
    const today = new Date();
    const date = convertToPST(today);
    const stickerDate = currentPost?.stickerSchedule?.stickerDate;

    if (
      currentPost?.slug === "cannabis-420-scavenger-hunt" ||
      stickerDate === date
    ) {
      setShowSticker(true);
    }
  }, []);

  useEffect(() => {
    if (paxSlugs.includes(window.location.pathname)) {
      includePAXScript();
    }
    // Adding onclick on sticker in text
    const textStickerContainer = document.getElementById(
      "text420StickerContainer"
    );
    if (textStickerContainer) {
      textStickerContainer.onclick = handleEmailPopup;
    }
    scriptCreate();
    aggleScript();
    delay();
  }, []);

  function scriptCreate() {
    if (isBrowser) {
      setTimeout(() => {
        const script = document.createElement("script");
        script.src = "https://e.infogram.com/js/dist/embed-loader-min.js";
        script.async = true;
        const scriptFunction = document.createElement("script");
        scriptFunction.innerHTML = `
      !function(e,i,n,s){var t="InfogramEmbeds",d=e.getElementsByTagName("script")[0];if(window[t]&&window[t].initialized)window[t].process&&window[t].process();else if(!e.getElementById(n)){var o=e.createElement("script");o.async=1,o.id=n,o.src="https://e.infogram.com/js/dist/embed-loader-min.js",d.parentNode.insertBefore(o,d)}}(document,0,"infogram-async");`;
        document.body.appendChild(script);
        document.body.appendChild(scriptFunction);
      }, 500);
    }
  }

  const trichomeToggle = () => {
    setTrichomeOpen(!trichomeOpen);
  };

  const customStylesPopUp = {
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    content: {
      position: "relative",
      background: "rgb(255, 255, 255)",
      maxWidth: "700px",
      overflow: "hidden",
      padding: "0",
      border: "none",
      borderRadius: "0",
      height: "350px",
      width: "100%",
      inset: "0",
      maxHeight: "350px",
      margin: "10px"
    }
  };

  const postSchema = {
    "@context": "http://schema.org",
    "@type": "Article",
    mainEntityOfPage: `${deployUrl}/${categoryPath(currentPost.categories)}/${
      (currentPost || {}).slug
    }/`,
    headline: currentPost.title,
    datePublished: currentPost.ISODate,
    dateModified: currentPost.ISOModified,
    author: {
      "@type": "Person",
      name: currentPost.author.node.name,
      url: `${deployUrl}/authors/${(currentPost.author.node || {}).slug}/`
    },
    description:
      ((currentPost.acf || {}).seo || {}).meta_description ||
      currentPost.content_post.postExcerpt,
    image: {
      "@type": "ImageObject",
      url: ((currentPost.featuredImage || {}).node || {}).sourceUrl
    },
    publisher: {
      "@type": "Organization",
      name: "Herb",
      logo: {
        "@type": "ImageObject",
        url: `${deployUrl}/icons/icon-512x512.png`
      }
    }
  };

  let webpageSchema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "@id": "#webpage",
    author: {
      "@type": "Organization",
      name: "Herb",
      logo: LOGO_URL
    }
  };

  if ((currentPost.acf || {}).reviewed_by) {
    webpageSchema.reviewedBy = {
      "@type": "Person",
      name: ReactHtmlParser(currentPost.acf.reviewed_by.display_name)[0],
      jobTitle: "Doctor"
    };
  }

  const breadcrumbs = [
    {
      name: catName,
      url: `/${catSlug}`
    },
    {
      name: currentPost.title,
      url: `/${categoryPath(currentPost.categories)}/${
        (currentPost || {}).slug
      }/`
    }
  ];

  // const { location, loading } = useContext(LocationContext);

  const categories =
    typeof currentPost.categories === Array
      ? currentPost.categories
      : currentPost.categories.nodes;

  let headingCounter = 0;

  var AdShown = false;

  var brandsShown = false;

  const {
    categories: { nodes: category }
  } = currentPost;

  function showAd(index) {
    const lengthOfContent = currentPost.content_post.content.length;

    if (
      !displayAdsToHide.includes((currentPost || {}).slug) &&
      // lengthOfContent > 2 &&
      index % 2 !== 0 &&
      !AdShown &&
      index >= Math.round(lengthOfContent / 2)
    ) {
      AdShown = true;

      return (
        <DisplayAd
          adUnit="21810472093/Learn//Article"
          slotId="div-gpt-ad-1631118969165-0"
          visibleProps={{
            margin: "30px 0px"
          }}
          banners={[
            {
              breakpoint: "mobile",
              sizes: [
                [300, 50],
                [320, 50]
              ]
            },
            {
              breakpoint: "tablet",
              sizes: [
                [320, 50],
                [728, 90]
              ]
            },
            {
              breakpoint: "desktop",
              sizes: [[728, 90]]
            }
          ]}
        />
      );
    }
  }

  function showBrands(index) {
    const lengthOfContent = currentPost.content_post.content.length;
    const middlePoint = Math.round(lengthOfContent / 2);

    if (index >= middlePoint) {
      if (!brandsShown) {
        for (let i = middlePoint; i < lengthOfContent; i++) {
          if (
            currentPost.content_post.content[i]?.__typename ===
            "WordpressWpPost_ContentPost_Content_Heading"
          ) {
            brandsShown = true;

            return <LoadRandomBrands />;
          } else if (
            currentPost.content_post.content[i + 1]?.__typename ===
            "WordpressWpPost_ContentPost_Content_Heading"
          ) {
            brandsShown = true;

            return <LoadRandomBrands />;
          }
          if (brandsShown) {
            break;
          }
        }
      }
    }
  }
  const nextArticletoShow =
    sameCatArticle[generateRandom(0, sameCatArticle.length - 1)];

  return (
    <Layout path={catPath}>
      <>
        {noindex ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : (
          <></>
        )}
      </>
      <SEO
        title={((currentPost.acf || {}).seo || {}).title || currentPost.title}
        shareTitle={((currentPost.acf || {}).seo || {}).share_title}
        description={
          ((currentPost.acf || {}).seo || {}).meta_description ||
          currentPost.content_post.postExcerpt
        }
        image={((currentPost.featuredImage || {}).node || {}).sourceUrl}
        url={`/${categoryPath(currentPost.categories)}/${
          (currentPost || {}).slug
        }`}
        canonical={`${deployUrl}/${categoryPath(currentPost.categories)}/${
          (currentPost || {}).slug
        }`}
        breadcrumbs={breadcrumbs}
      />
      <JsonLd data={webpageSchema} />
      <JsonLd data={postSchema} />
      {emailPopupOpen && (
        <ScavengerHuntPopUp
          handleEmailPopup={handleEmailPopup}
          articleSlug={(currentPost || {}).slug}
        />
      )}
      <div className="aticleBreadcrumb">
        <div className="readNow">
          <Link
            className="readLink"
            to={`/${categoryPath(currentPost.categories)}/${
              (currentPost || {}).slug
            }`}
          >
            <p className="nameOfArticle">
              <span className="readingLink">Now Reading:</span>
              {category[0].name} | {ReactHtmlParser(currentPost.title)}
            </p>
          </Link>
        </div>
        <div className="socialLinks">
          <p className="shareLinks">Share:</p>
          <ShareLinks
            url={`/${categoryPath(currentPost.categories)}/${
              (currentPost || {}).slug
            }/`}
            article={currentPost}
            media={
              (currentPost || {}).featuredImage
                ? ((currentPost.featuredImage || {}).node || {}).sourceUrl
                : ""
            }
          />
        </div>
        {nextArticletoShow && (
          <div className="readNext">
            <Link
              className="readLink"
              to={`/${getCategoryPath((nextArticletoShow || {}).categories)}/${
                (nextArticletoShow || {}).slug
              }`}
            >
              <p className="nameOfArticle">
                <span className="readingLink">Next:</span>
                {
                  ((nextArticletoShow.categories || {}).nodes[0] || {}).name
                } | {ReactHtmlParser((nextArticletoShow || {}).title)}
              </p>
            </Link>
          </div>
        )}
      </div>
      <div className="aticleContentContainer">
        <div className="articlePageBanner">
          <div className="articlePageBannerImage">
            <Image
              className="lazyload bannerImage"
              src={((currentPost.featuredImage || {}).node || {}).sourceUrl}
              alt={
                ((currentPost.featuredImage || {}).node || {}).altText ||
                currentPost.title
              }
              width={920}
              height={464}
              imgixParams={{
                fit: "clip"
              }}
            />
            {currentPost?.mainImageSticker?.addSticker && showSticker && (
              <div
                className="mainImageStickerContainer"
                onClick={handleEmailPopup}
              >
                <Image
                  className="lazyload mainImageSticker"
                  src={
                    ((currentPost.mainImageSticker || {}).sticker420 || {})
                      .sourceUrl
                  }
                  alt="420 Scavenger Hunt Sticker"
                  width={150}
                  height={150}
                  imgixParams={{
                    fit: "clip"
                  }}
                />
              </div>
            )}
          </div>
          <div className="articleImageCredits">
            <div className="imageCourtesy">
              {ReactHtmlParser(
                ((currentPost.featuredImage || {}).node || {}).caption
              )}
            </div>
          </div>
          <div className="CategoryAndDateDiv">
            <ArticleBreadCrumb
              category={category}
              categories={currentPost.categories}
              date={(currentPost || {}).ISOModified}
              post={currentPost}
            />
          </div>
          <div className="articlePageContent">
            <h1 className="articlePageH1">{currentPost.title}</h1>
            <p className="articlePageP">
              {(currentPost.content_post || {}).postExcerpt}
            </p>
            <div className="articlePageWrittenBy">
              <WrittenBy author={currentPost.author} />
            </div>
          </div>
        </div>

        <div className="featuredArticlePage">
          <div className="featuredContentDiv">
            {((currentPost.content_post || {}).content || []).map(
              (item, index) => {
                if (
                  item.__typename ===
                    "WordpressWpPost_ContentPost_Content_Heading" &&
                  item.level === "level1"
                ) {
                  headingCounter += 1;
                }

                if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_Image"
                )
                  return (
                    <>
                      <ImageForArticlePage
                        item={item}
                        postTitle={currentPost.title}
                        key={`${item.__typename}-${index}`}
                      />
                      {showAd(index)}
                    </>
                  );
                if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_ExternalProduct"
                )
                  return (
                    <>
                      <ArticleProduct
                        item={item}
                        key={`${item.__typename}-${index}`}
                      />
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_Quote"
                )
                  return (
                    <>
                      <BoldTextForArticlePage
                        item={item}
                        key={`${item.__typename}-${index}`}
                      />
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename === "WordpressWpPost_ContentPost_Content_Text"
                )
                  return (
                    <>
                      <DescriptionForArticlePage
                        item={item}
                        showSticker={showSticker}
                        key={`${item.__typename}-${index}`}
                      />
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename ===
                    "WordpressWpPost_ContentPost_Content_Products" &&
                  item.product
                )
                  return (
                    <>
                      {item.product.databaseId !== null ? (
                        <div key={`${item.__typename}-${index}`}>
                          <div className="articlePageProducts">
                            <ProductCard
                              productdetails={item.product}
                              articlePage={true}
                            />
                          </div>
                          {showAd(index)}
                        </div>
                      ) : null}
                    </>
                  );
                else if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_Button"
                )
                  return (
                    <>
                      <Button
                        key={`${item.__typename}-${index}`}
                        role="link"
                        to={item.url}
                      >
                        {item.text}
                      </Button>
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_InstagramEmbed"
                )
                  return (
                    <>
                      <PostInstagram
                        key={`${item.__typename}-${index}`}
                        instagram_id={item.instagramId}
                      />
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_YoutubeEmbed"
                )
                  return (
                    <>
                      <PostVideo
                        key={`${item.__typename}-${index}`}
                        type="youtube"
                        video_id={item.videoId}
                      />
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_TwitterEmbed"
                )
                  return (
                    <>
                      <PostTweet
                        key={`${item.__typename}-${index}`}
                        tweet={item.tweetId}
                      />
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_Iframe"
                )
                  return (
                    <>
                      <PostIframe
                        key={`${item.__typename}-${index}`}
                        html={item.iframe}
                      />
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_HerbVideo"
                )
                  return (
                    <>
                      <PostVideo
                        key={`${item.__typename}-${index}`}
                        type="herb"
                        video_id={item.videoId}
                      />
                      {showAd(index)}
                    </>
                  );
                else if (
                  item.__typename ===
                  "WordpressWpPost_ContentPost_Content_Heading"
                )
                  return (
                    <>
                      {/* {showBrands(index)} */}
                      {item.level === "level1" ? (
                        <div className="headingWithSticker">
                          <h1
                            className="articlePageH1"
                            key={`${item.__typename}-${index}`}
                          >
                            {item.text}
                          </h1>
                          {item?.addSticker && showSticker && (
                            <div
                              className="headingStickerContainer"
                              onClick={handleEmailPopup}
                            >
                              <Image
                                className="lazyload headingSticker"
                                src={item?.sticker420?.sourceUrl}
                                alt="420 Scavenger Hunt Sticker"
                                imgixParams={{
                                  fit: "clip"
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ) : item.level === "level2" ? (
                        <div className="headingWithSticker">
                          <h2
                            className="articleSubHeadings"
                            key={`${item.__typename}-${index}`}
                          >
                            {item.text}
                          </h2>
                          {item?.addSticker && showSticker && (
                            <div
                              className="headingStickerContainer"
                              onClick={handleEmailPopup}
                            >
                              <Image
                                className="lazyload headingSticker"
                                src={item?.sticker420?.sourceUrl}
                                alt="420 Scavenger Hunt Sticker"
                                imgixParams={{
                                  fit: "clip"
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="headingWithSticker">
                          <h3
                            className="articleSubHeadingsH3"
                            key={`${item.__typename}-${index}`}
                          >
                            {item.text}
                          </h3>
                          {item?.addSticker && showSticker && (
                            <div
                              className="headingStickerContainer"
                              onClick={handleEmailPopup}
                            >
                              <Image
                                className="lazyload headingSticker"
                                src={item?.sticker420?.sourceUrl}
                                alt="420 Scavenger Hunt Sticker"
                                imgixParams={{
                                  fit: "clip"
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {showAd(index)}
                    </>
                  );
              }
            )}
            <div className="tagsForArticles">
              {currentPost.tags.nodes.map(tag => (
                <CategoryTags tag={tag} key={tag.id} />
              ))}
            </div>
            <div className="shareArticleLinks">
              <ShareLinks
                url={`/${categoryPath(currentPost.categories)}/${
                  (currentPost || {}).slug
                }/`}
                article={currentPost}
                media={
                  (currentPost || {}).featuredImage
                    ? ((currentPost.featuredImage || {}).node || {}).sourceUrl
                    : ""
                }
              />
            </div>

            {!displayAdsToHide.includes((currentPost || {}).slug) && (
              <DisplayAd
                visibleProps={{
                  margin: "20px 0px"
                }}
                adUnit="21810472093/Learn//Article"
                slotId="div-gpt-ad-1631118969165-0"
                banners={[
                  {
                    breakpoint: "mobile",
                    sizes: [
                      [300, 50],
                      [320, 50]
                    ]
                  },
                  {
                    breakpoint: "tablet",
                    sizes: [
                      [320, 50],
                      [728, 90]
                    ]
                  },
                  {
                    breakpoint: "desktop",
                    sizes: [[728, 90]]
                  }
                ]}
              />
            )}
          </div>

          <div className="articleAndNewsletter">
            <SidebarBrandsAndProducts />
            <div className="sidebarBrands">
              <LoadRandomBrands
                brandsToShow={2}
                headingFontSize={"14px"}
                borderTop={"none"}
              />
            </div>
          </div>
        </div>

        {!hideRecommendedProductsSection ? (
          <div className="herbRandomRecommendedProducts">
            <p
              css={{ fontSize: "30px" }}
              className="recommendedProductsHeading"
            >
              Herb <span>Recommended</span> Products:
            </p>
            <LoadRandomProducts />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="forLatestArticles">
        <SectionHeading heading="READ MORE" />

        {related_links_page ? (
          pagelinks ? (
            pagelinks?.related_links ? (
              pagelinks.related_links.map(item => {
                return <RelatedLinksCard link={item} />;
              })
            ) : (
              <LatestArticleLoadAlgolia posts={latestArticles} />
            )
          ) : (
            <LatestArticleLoadAlgolia posts={latestArticles} />
          )
        ) : (
          <LatestArticleLoadAlgolia posts={latestArticles} />
        )}
      </div>
      {isBrowser ? (
        ilgmSlugs.includes(window.location.pathname) ? (
          <Modal isOpen={trichomeOpen} style={customStylesPopUp}>
            <div
              className="ArticlePopUPModal"
              style={{
                height: "100%"
              }}
            >
              <div className="referralCloseIcon">
                <Box
                  width={["24px", "", "24px"]}
                  css={{
                    background: "white",
                    borderRadius: "50%"
                  }}
                  onClick={trichomeToggle}
                >
                  <Icon
                    icon="close"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  />
                </Box>
              </div>
              <TrichomePopUpForm />
            </div>
          </Modal>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <Container>
        {!displayAdsToHide.includes((currentPost || {}).slug) && (
          <DisplayAd
            adUnit="21810472093/Learn//Article"
            slotId="div-gpt-ad-1631118969165-0"
            visibleProps={{
              margin: "120px 0px"
            }}
            banners={[
              {
                breakpoint: "mobile",
                sizes: [
                  [300, 50],
                  [320, 50]
                ]
              },
              {
                breakpoint: "tablet",
                sizes: [
                  [320, 50],
                  [728, 90]
                ]
              },
              {
                breakpoint: "desktop",
                sizes: [[970, 250]]
              }
            ]}
          />
        )}
      </Container>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!, $catName: String!) {
    wordpressWpPost(id: { eq: $id }) {
      id
      title
      slug
      status
      databaseId
      tags {
        nodes {
          id
          slug
          name
        }
      }
      stickerSchedule {
        stickerDate
      }
      categories {
        nodes {
          name
          slug
          databaseId
          wpParent {
            node {
              id
            }
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
          altText
          caption
          srcSet
          sizes
        }
      }
      mainImageSticker {
        sticker420 {
          sourceUrl
        }
        addSticker
      }
      date(formatString: "MM,DD,YYYY")
      modified(formatString: "MMMM DD, YYYY")
      ISODate: date
      ISOModified: modified
      author {
        node {
          name
          slug
          contentUser {
            userImage {
              sourceUrl
              altText
            }
          }
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      hideRecommendedProducts {
        hide
      }
      content_post {
        postExcerpt
        content {
          ... on WordpressWpPost_ContentPost_Content_Heading {
            __typename

            level
            text
            fieldGroupName
            addSticker
            sticker420 {
              sourceUrl
            }
          }
          ... on WordpressWpPost_ContentPost_Content_Text {
            __typename

            text
            addSticker
            sticker420 {
              sourceUrl
            }
          }
          ... on WordpressWpPost_ContentPost_Content_HerbVideo {
            __typename

            videoId
            fieldGroupName
          }
          ... on WordpressWpPost_ContentPost_Content_Image {
            __typename
            imageLink
            image {
              sourceUrl
              altText
              caption
            }
          }
          ... on WordpressWpPost_ContentPost_Content_TwitterEmbed {
            __typename

            tweetId
          }
          ... on WordpressWpPost_ContentPost_Content_YoutubeEmbed {
            __typename

            videoId
          }
          ... on WordpressWpPost_ContentPost_Content_InstagramEmbed {
            __typename

            instagramId
            fieldGroupName
          }
          ... on WordpressWpPost_ContentPost_Content_Quote {
            __typename

            attribution
            text
          }
          ... on WordpressWpPost_ContentPost_Content_Iframe {
            __typename

            iframe
            fieldGroupName
          }
          ... on WordpressWpPost_ContentPost_Content_Products {
            __typename

            productLayout
            fieldGroupName
            product {
              ... on WordpressWpProduct {
                __typename
                ...Product
              }
            }
          }
          ... on WordpressWpPost_ContentPost_Content_ExternalProduct {
            __typename

            brand
            fieldGroupName
            title
            url
            image {
              sourceUrl
              altText
              caption
            }
          }
          ... on WordpressWpPost_ContentPost_Content_GrowingSignup {
            __typename

            fieldGroupName
          }
          ... on WordpressWpPost_ContentPost_Content_NewsletterSignup {
            __typename

            fieldGroupName
            newsletter
            text
            title
          }
          ... on WordpressWpPost_ContentPost_Content_Button {
            __typename

            buttonText
            fieldGroupName
            url
          }
        }
      }
    }

    sameCatPosts: allWordpressWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: $catName } } } }
        id: { ne: $id }
      }
      limit: 4
    ) {
      nodes {
        ...Post
      }
    }

    newPosts: allWordpressWpPost(
      filter: { id: { ne: $id } }
      sort: { fields: [date], order: DESC }
      limit: 10
    ) {
      nodes {
        ...Post
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
